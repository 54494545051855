<template>
  <div>
    <parcelamento-base
      ref="parcelamentoBase"
      :filtros-rapidos-custom="filtrosRapidos"
      :colunas-custom="true"
      :novas-colunas="colunas"
      :acoes-custom="true"
    >
      <template
        slot="colunasCustom"
        slot-scope="{props} "
      >
        <div
          v-if="props.column.field === 'nmrUltimaParcelaDe'"
          class="text-center"
        >
          <span
            v-for="(valor, $index) in props.row.parcelas"
            :key="$index"
          >
            <!-- class="badge badge-primary ml-50" -->
            <div style="white-space:nowrap; margin-bottom: 5px;">
              {{ valor.nmrUltimaParcelaDe }}
            </div>
          </span>
        </div>
        <div
          v-else-if="props.column.field === 'mesUltimaParcela'"
        >
          <span
            v-for="(valor, $index) in props.row.parcelas"
            :key="$index"
          >
            <!-- class="badge badge-primary ml-50" -->
            <div style="white-space:nowrap; margin-bottom: 5px;">
              {{ valor.mesUltimaParcela }}
            </div>
          </span>
        </div>
        <div
          v-else-if="props.column.field === 'dataVencimento'"
        >
          <span
            v-for="(valor, $index) in props.row.parcelas"
            :key="$index"
          >
            <!-- class="badge badge-primary ml-50" -->
            <div style="white-space:nowrap; margin-bottom: 5px;">
              {{ valor.vencimento }}
            </div>
          </span>
        </div>
        <div
          v-if="props.column.field === 'parcelasEmAtraso'"
          class="text-center"
        >
          <div
            v-for="(valor, $index) in props.row.parcelas"
            :key="$index"
          >
            <b-badge
              :variant="valor.parcelasEmAtraso ? 'light-danger' : 'light-success'"
              style="margin-bottom: 5px;"
            >
              {{ valor.parcelasEmAtraso ? 'Sim' : 'Não' }}
            </b-badge>
          </div>
        </div>
      </template>
      <template
        slot="acoesCustom"
        slot-scope="{props} "
      >
        <acoes
          :ref="`acoes${props.row.id}`"
          :grid-props="props"
          :request-parcelas="$refs.parcelamentoBase.getParcelas"
          :request-parcelas-recentes="$refs.parcelamentoBase.getParcelasRecentes"
          :request-enviar-email="$refs.parcelamentoBase.enviaParcelaEmail"
          :request-guia-parcelamento="$refs.parcelamentoBase.getGuiaParcelamento"
          :tipo-parcelamento="$refs.parcelamentoBase.tipoParcelamento"
          :api="$refs.parcelamentoBase.apiParcelamento($refs.parcelamentoBase.tipoParcelamento)"
        />
      </template>
    </parcelamento-base>
  </div>
</template>

<script>

export default {
  components: {
    ParcelamentoBase: () => import('../ParcelamentoView.vue'),
    Acoes: () => import('./shared/components/acoes/Acoes.vue'),
  },
  data() {
    return {
      colunas: [{ label: 'Razão social', field: 'razaoSocial', type: 'custom' },
        { label: 'CNPJ/CPF', field: 'cadastroFederal', tdClass: 'col-tamanho-cnpj' },
        { label: 'Nº Processo', field: 'identificacao', tdClass: 'col-tamanho-cnpj' },
        { label: 'Situação', field: 'situacao', type: 'custom' }, // regular ou irregular
        { label: 'Data vencimento', field: 'dataVencimento', type: 'custom' },
        {
          label: 'Última parcela lida', field: 'mesUltimaParcela', tdClass: 'alinhar-coluna', type: 'custom',
        },
        { label: 'Parcela', field: 'nmrUltimaParcelaDe', type: 'custom' },
        { label: 'Parcelas em atraso', field: 'parcelasEmAtraso', type: 'custom' },
        { label: 'Consultado em', field: 'ultimaExecucao' }],
      filtrosRapidos: [
        {
          id: 'EmParcelamentoAguardando',
          colunas: ['Situacao'],
          label: 'Em parcelamento',
          valor: '1',
        },
        {
          id: 'StatusEmAtraso',
          colunas: ['StatusEmParcelamento'],
          label: 'Possui Parcelas em Atraso',
          valor: '2',
        },
        {
          id: 'StatusSemAtraso',
          colunas: ['StatusEmParcelamento'],
          label: 'Não Possui Parcelas em Atraso',
          valor: '1',
        },
        {
          id: 'MesPaga',
          colunas: ['MesPaga'],
          label: 'Parcela do Mês Paga',
          valor: '1',
        },
        {
          id: 'MesNaoPaga',
          colunas: ['MesNaoPaga'],
          label: 'Parcela do Mês não Paga',
          valor: '2',
        },
        {
          id: 'AguardandoConcluir',
          colunas: ['Situacao'],
          label: 'Parcelamento Aguardando Concluir',
          valor: '3',
        }],
    }
  },
}
</script>

<style>

</style>
